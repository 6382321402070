@import "../settings/global";

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 50px 0;
}

.logo-area {
  &__name {
    font-weight: $semi-bold-weight;
    font-size: $regular-font;
    text-transform: uppercase;
  }

  &__tagline {
    font-size: $small-font;
    font-weight: $regular-font;
  }
}

.nav {
  display: flex;
  flex-direction: row;

  &__item {
    font-size: $small-font;
    font-weight: $medium-weight;
    color: $text-color;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 24px;
    }
  }
}

@media only screen and (max-width: 640px) {
  .nav {
    &.hide {
      display: none;
    }
  }

  .header {
    padding: 30px 0;
  }
}
