@import "../../settings/global";

.rails-section {
  margin-bottom: 100px;
}

.rail {
  &__container {
    height: 150px;
    width: 100%;
    overflow-x: hidden;

    &.theme {
      .rail__track {
        animation: scroll-left 17s linear infinite;
      }

      .rail__item {
        color: $theme-color;
      }
    }
  }
  &__track {
    white-space: nowrap;
    width: 100%;
    height: 100%;
    margin: 0;
    line-height: 50px;
    transform: translateX(0%);
    animation: scroll-left 26s linear infinite;

    &.first {
      .rail__item:last-child {
        &:after {
          content: "HackHarvard 2019.";
          margin-left: 60px;
        }
      }
    }
    &.second {
      .rail__item:last-child {
        &:after {
          content: "Awwwards.";
          margin-left: 60px;
        }
        &:before {
          content: "Blogger.";
          padding-right: 60px;
        }
      }
    }
    &.third {
      .rail__item:last-child {
        &:before {
          content: "Hackathon master 😉";
          padding-right: 60px;
        }
        &:after {
          content: "Coder.";
          margin-left: 60px;
        }
      }
    }
  }

  &__item {
    line-height: 150px;
    font-size: $x-large;
    font-weight: $medium-weight;
    color: $text-color;
    padding-right: 60px;
  }

  &__divider {
    max-width: 69.375em;
    border-top: none;
    border-bottom: 1px solid $theme-color;
  }
}

@keyframes scroll-left {
  0% {
    transform: translateX(0%);
    // opacity: 0;
  }
  5% {
    // opacity: 0;
  }
  10% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    // opacity: 0;
    transform: translateX(-200%);
  }
}

@media only screen and (max-width: 640px) {
  .rail {
    &__container {
      height: 80px;
    }
    &__item {
      font-size: $large-font;
      padding-right: 30px;
      line-height: 80px;
    }
  }

  .rails-section {
    margin-bottom: 0;
  }
}
