@import "../../settings/global";

.featuredWork {
  position: relative;
  background: linear-gradient(
    180deg,
    #0a0716 3.66%,
    rgba(10, 7, 22, 0.8) 7.55%,
    rgba(10, 7, 22, 0.8) 89.27%,
    #0a0716 100%
  );
  margin-top: 100px;
  // z-index:-2;

  &__title {
    text-align: right;
  }

  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url("../../../pages/homepage/images/typewriter.jpg");
    width: 100%;
    height: 100%;
    background-attachment: fixed;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
  }

  .section {
    position: relative;
    z-index: 1;
    padding: 100px 0;
  }

  &__item {
    font-size: $x-large;
    font-weight: $bold-weight;
    text-align: right;
    scroll-snap-align: center;

    width: 100%;
    height: 220px;
    line-height: 220px;
    -webkit-text-stroke: 1px white;
    text-stroke: 1px white;
    color: transparent;
    &:first-child {
      margin-top: 40px;
    }

    &.active {
      color: white;
      -webkit-text-stroke: 0;
      text-stroke: 0;
    }

    &:hover {
      -webkit-text-stroke-color: $theme-color;
    }
  }

  &.red,
  &.red-two {
    background: rgba(22, 7, 7, 0.8);
  }
  &.green,
  &.green-two {
    background: rgba(7, 22, 8, 0.8);
  }
  &.blue {
    background: rgba(10, 7, 22, 0.8);
  }
  &.yellow,
  &.yellow-two {
    background: rgba(22, 16, 7, 0.8);
  }
  &.purple,
  &.purple-two {
    background: rgba(19, 7, 22, 0.8);
  }
}

@media only screen and (max-width: 1024px) {
  .featuredWork {

    &__item{
        line-height: normal;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size:$extra-80;
    }
  }
}

@media only screen and (max-width: 640px) {
  .featuredWork {
    margin-top: 0;

    &__item {
      font-size: $large-font;
      height: 100px;
    }

    &::after {
      background-size: cover;
      background-position: left;
    }
  }
}
