@import "../settings/global";

#cursor {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  position: absolute;
  transform: translate(-50%, -50%);
  transition: top ease-in-out, left ease-in-out;
  border: 2px solid $text-color;
  // display: grid;
  // place-items: center;
  display:flex; 
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  opacity: 1;
  transition: opacity 1.2s;
  z-index: -1;
  transition: width $transition-snap, height $transition-snap,
    border-radius $transition-snap, place-items $transition-snap, align-items $transition-snap, justify-content $transition-snap;

  #small-cursor {
    width: 14px;
    height: 14px;
    border-radius: 8px;
    background-color: $text-color;
    transition: clip-path $transition-snap, height $transition-snap,
      place-items $transition-snap, width $transition-snap,
      justify-content $transition-snap, width $transition-snap,
      align-items $transition-snap, width $transition-snap,
      border-radius $transition-snap, background-size $transition-snap,
      background-color $transition-snap;
    // clip-path: circle(50% at 50% 50%);
    background-size: 0;
  }

  &.hide {
    opacity: 0;
    transition: 1.2s;
    transition-delay: 3s;
  }

  &.play {
    height: 80px;
    width: 80px;
    transition: height $transition-snap, width $transition-snap,
      border-radius $transition-snap, background-color $transition-snap;
    border-radius: 40px;

    #small-cursor {
      height: 40px;
      width: 40px;
      transition: clip-path $transition-snap, height $transition-snap,
        width $transition-snap, background-size $transition-snap;
      border-radius: 20px;

      &.play-icon {
        background-image: url(../../images/icons/play-rounded.png);
      }

      &.chevron-icon {
        background-image: url(../../images/icons/chevron-right-rounded.png);
      }

      background-position: center;
      background-repeat: no-repeat;
      background-size: 28px;
      // clip-path: polygon(54% 0, 100% 50%, 54% 100%, 0% 100%, 46% 50%, 0% 0%);
      // background-color:$theme-color;
    }

    &.bg-transparency {
      #small-cursor {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }

  &.double-tap {
    height: 300px;
    width: 300px;
    border-radius: 150px;

    #small-cursor {
      height: 100%;
      width: 100%;
      border-radius: 150px;
      transition: 0.4s 0.1s cubic-bezier(0.4, 0.19, 0.43, 0.88);
    }

    &::after {
      // display: grid;
      // place-items: center;

      display:flex; 
      flex-direction: column;
      justify-content: center;
      align-items: center;

      height: 100px;
      width: 100px;
      content: "double tap for code";
      border-radius: 50px;
      box-sizing: border-box;
      // border: 2px solid $theme-color;
      background-color: #092839;
      position: absolute;
      z-index: 2;
      top: -10px;
      right: -10px;
      padding: 5px;
      text-align: center;
      font-size: 13px;
    }
  }

  // &.hollow-small-cursor{
  //   place-items: start start;
  //   #small-cursor{
  //     // background-color:transparent;
  //     // border:2px solid white;
  //     height:18px; width:18px; border-radius:9px; background-color: transparent; border:2px solid white;
  //   }
  // }

  // &.for-work-section{
  //   // display:none;
  //   z-index: 2;
  //   background-color:red;
  // }

  // &.over-item{
  //   cursor:pointer !important;
  // }
}

@media only screen and (max-width: 640px) {
  #cursor {
    display: none;
  }
}
