@import "../settings/global";

.footer {
  &__text {
    text-align: center;
    font-size: $large-font;
    font-weight: $medium-weight;
  }

  &__email {
    text-align: center;
    font-size: $extra-80;
    font-weight: $bold-weight;
    color: white;
    display: block;
    width: 100%;
    transition: color 0.4s ease-in-out;
    -webkit-text-stroke: 0;
    text-stroke: 0;

    // &:hover{
    //     -webkit-text-stroke: 1px white;
    //     text-stroke: 1px white;
    //     color:transparent;
    //     transition:color 0.4s ease-in-out;
    // }

    span {
      -webkit-text-stroke: 1px white;
      text-stroke: 1px white;
      color: transparent;
    }
  }

  .social_links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 620px;
    margin: 92px auto 0 auto;

    &__item {
      font-size: $regular-font;
      font-weight: $medium-weight;
      color: white;
    }
  }

  .credits {
    margin-top: 114px;

    &__item {
      color: white;
      font-size: $small-font;

      &:first-child {
        text-align: left;
      }
      &:nth-child(2) {
        text-align: center;
      }
      &:nth-child(3) {
        text-align: right;
      }
    }
  }
}

@media only screen and (max-width: 1024px){
    .footer{
        &__email{
            font-size: $large-font;
        }
    }
}

@media only screen and (max-width: 640px) {
  .footer {
    &__email {
      font-size: 22px;
    }

    &__text {
      font-size: $heading-font;
    }
    .social_links {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      &__item {
        font-size: $small-font;
        font-weight: $regular-weight;
        padding: 10px;
      }
    }

    .credits {
      &__item {
        &:first-child,
        &:nth-child(3) {
          display: none;
        }
      }
    }
  }
}