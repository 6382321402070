@import "../settings/global";
@import "../components/scroll-image.scss";

.wrapper {
  position: relative;
  width: 100%;
}

.dte {
  overflow-x:hidden;
  &__deliverables {
    margin: 100px 0 35px 0;
    font-size: $small-font;
    font-weight: $medium-weight;
  }

  &__title {
    max-width: 730px;
    font-weight: $semi-bold-weight;
    font-size: $large-font;
    margin-bottom: 35px;
  }

  &__image-container {
    background-color: #b6a0a0;
    border-radius: 5px;
    padding: 140px 50px 0 50px;
    overflow: hidden;
    height: 630px;

    &.equal-padding{
        padding:50px;
        height:auto;
        max-height: none;
    }

    img{
        width:100%;
        border-radius:5px;
        filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));
    }
  }

  .screenshot {
    width: 100%;
    border-radius: 5px;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));
    height: 530px;
    object-position: top;
    object-fit: cover;
    margin-bottom: -30px;
  }

  .iphone {
    width: 100%;
  }

  .scroll-image {
    position:absolute;
    right: calc((100vw - 1080px)/2 + 100px);
    top:280px;
  }

  &__two-images {
    border-radius: 5px;
    height:475px;
    overflow:hidden;

    img{
        width: 100%;
    }
  }
}

.dte-content {
  padding: 70px 0;
  p {
    font-size: $v-small-font;
    font-weight: 300;
  }

  &__heading {
    font-size: $v-small-font;
    font-weight: $medium-weight;
  }
}

.dte-iphone-section{
    .iphone{
        width:100%;
    }

    padding:250px 40px;
}

@media only screen and (max-width: 1024px){
  .dte{
      &__two-images{
          height:320px;
      }

      &__image-container{
        height:500px;
        padding-top:110px;
      }

      .screenshot{
        object-fit: contain;
      }

      .scroll-image{
        right:70px; top:180px;
      }
  }

  .dte-iphone-section{
    padding:200px 40px;
  }
}

@media only screen and (max-width: 640px){
   .dte{
       &__image-container{
           padding:10px;
           max-height: 600px;
           margin-bottom:60px;

           &.equal-padding{
             padding:10px;
             margin-bottom:0
           }
       }

       &__title{
         font-size: $heading-font;
         font-weight: $medium-weight;
       }

       &__deliverables{
         font-weight: $regular-weight;
         margin-top:50px;
       }

       .scroll-image{
        right: 30px;
        top: 330px;
       }

       .screenshot{
           display:none;
       }

       &__two-images:first-of-type{
         display:none;
       }
   }

   .dte-content{
     padding-top:0px;
     padding-bottom:50px;

     p{
       font-size:15px; 
       line-height:24px;
     }

     &__heading{
       font-size: 17px;
     }
   }

   .dte-iphone-section{
     padding:100px 20px;

     .iphone{
       &.right{
         margin-left:150px;
        transform: scale(0.8);
       }
     }
   }
}