@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

/* overwriting product.css */
.grid-container {
  max-width: 69.375em; /* making it 1080px including 15px margin on either sides */
}

/* Setting variables */
$primary-color: #0a0716;
$theme-color: #00e3ff;
$text-color: #fff;

$regular-weight: 400;
$medium-weight: 500;
$semi-bold-weight: 600;
$bold-weight: 700;

$v-small-font: 14px;
$small-font: 16px;
$regular-font: 18px;
$heading-font: 24px;
$large-font: 40px;
$extra-80: 80px;
$x-large: 100px;

$transition-snap: 0.3s ease-in-out;

html,
body {
  height: 100%;
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif;
}

body{
  background-color: $primary-color;
  color: white;
}

.section {
  padding: 80px 0;
}

h2 {
  font-size: $x-large;
  font-weight: $medium-weight;
}

// * {
//   cursor: none;
// }

*{
  box-sizing:border-box;
}

a, a:active, a:hover, a:focus{
  color:inherit;
}

@media only screen and (max-width: 640px){
  body{
    padding:0 10px;
  }

  h2{
    font-size:$large-font;
  }

  .section{
    padding:40px 0;
  }
}