@import "../../settings/global";
@import "../../components/scroll-image.scss";

.hero {
  .scroll-image {
    position: absolute;
    bottom: 40px;
    left: 400px;
  }
}

.description {
  font-size: $large-font;
  font-weight: $medium-weight;
  // padding:100px 0 160px 0;

  &:first-child {
    display: none;
  }

  &:nth-child(2) {
    display: block;
  }

  .highlight {
    // text-decoration: underline;
    // text-decoration-color: $theme-color;
    // text-decoration-thickness: 10px;
    position: relative;
    display: inline-block;
    &:after {
      position: absolute;
      bottom: 8px;
      left: 0;
      height: 11px;
      width: 100%;
      background-color: $theme-color;
      content: "";
      z-index: -1;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.parallax-inner {
  z-index: 1;
  position: relative;
}

@media only screen and (min-width: 1024px) {
  .description {
    margin-bottom: 100px;
    &__container {
      height: calc(100vh - 160px);
    }
  }
}

@media only screen and (max-width: 640px) {
  .hero {
    .scroll-image {
      position: absolute;
      bottom: 40px;
      right: 40px;
      left:auto;
    }
  }
  .description {
    // font-size: $large-font;

    &:first-child {
      display: block;
    }

    &:nth-child(2) {
      display: none;
      font-size: $heading-font;
      margin: 120px 0;
      font-weight: $regular-weight;
    }

    .align-left,
    .align-right,
    .align-center {
      display: block;
    }

    .align-center {
      text-align: center;
    }

    .align-right {
      text-align: right;
    }

    .align-left {
      text-align: left;
    }

    &__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: calc(100vh - 110px);
      padding-bottom: 120px;
    }

    .highlight {
      &:after {
        height: 6px;
      }
    }
  }

  .minion-container {
    width: 250px;
    padding-left: 25px;
    margin: auto;
    margin-top: 30px;
    transform: none;
  }
}
