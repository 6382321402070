@import "../settings/global";

.content-card {
  border: 1px solid $theme-color;
  border-radius: 5px;
  padding: 30px;
  width: 100%;

  &__image {
    width: 100%;
    height: 100%;
    // background-color: white;
    margin-bottom: 24px;
    border-radius: 3px;

    /* this puts the image below cursor */
    // position: relative; z-index: -2;
  }

  &__tag {
    font-size: $small-font;
    font-weight: $medium-weight;
    margin-bottom: 10px;
    text-transform: capitalize;
    color: white;

    // &.podcast{
    //     color:#1DB954;
    // }
  }

  &__date {
    font-size: $v-small-font;
    font-weight: $regular-weight;
    color: white;
  }

  &__title {
    font-size: $heading-font;
    font-weight: $bold-weight;
    margin-bottom: 24px;
    color: white;
  }

  &__button {
    border: 1px solid white;
    height: 50px;
    text-align: center;
    border-radius: 5px;
    display: block;
    color: white;
    font-size: $small-font;
    font-weight: $semi-bold-weight;
    line-height: 50px;

    /* this puts the button below cursor */
    position: relative;
    z-index: -2 !important;
  }

  a.button {
    &:hover {
      .content-card__button {
        background-color: rgba(0, 227, 255, 0.15);
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  .content-card {
    margin-top: 30px;
    padding: 15px 10px;
    display: flex;
    flex-direction: row;

    &__text-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__image {
      width: 100px;
      height: 100px;
      margin: 0 10px 0 0;
    }

    &__tag {
      font-size: $v-small-font;
      font-weight: $regular-weight;
    }

    &__title {
      font-size: $regular-font;
      font-weight: $regular-weight;
      margin: 0;
      max-height: 69px;
      overflow: hidden;
    }

    &__button {
      display: none;
    }
  }
}
