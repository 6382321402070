.scroll-image {
  height: 110px;
  width: 110px;
  animation: round 3s infinite linear;
}

@keyframes round {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
